.left {
    float: left;
    width: 50%;
    overflow-x: hidden;
}
.right {
    float: right;
    width: 50%;
    height: 100%;
    display: table-cell;

    vertical-align: middle;
    text-align: center;
    
}

.fotos {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    height:100vh;
    float: left;
}

.logotipo {
    max-width: 20%;
    display:inline;
}

.group:after {
    content:"";
    display: table;
    clear: both;
}
@media screen and (max-width: 480px) {
    .left, .right {
        float: none;
        width: auto;
    }
}

.header {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.vertical-align {
    padding-top: 40%;
    display: inline-table;
    table-layout: fixed;
    width: 100%;
    height: 400px;
}
.horizontal-align{
    display: inline-block;
}

.morada{
    padding-top: 0px;
    padding-bottom: 0px;
    display:inline;
    max-width: 50%;
    max-height: 50%;
}

.slick-arrow .slick-next {
    display: none;
}